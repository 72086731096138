<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => f()"
        :canNavigate="!isSelfFulfiller"
        fluid
        :hideRestart="isSelfFulfiller"
        :hideYesNo="isSelfFulfiller"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showSkip="isSelfFulfiller"
        :stage.sync="stage"
        step="fulfiller-pointers"
        title="Fulfillment Coverage">
        <template v-slot:body="{ next }">
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <p>In order for BlitzIt Web to automatically select who is fulfilling an order, you need to specify what areas each 3PL service covers.  You can do that by adding fulfillment pointers below.</p>
                    
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="fulfiller-pointers"
                        :onPullSuccessAsync="pullPointers"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-avatar>
                                <v-icon v-if="item.boundary != null" class="my-auto">mdi-texture-box</v-icon>
                                <v-icon v-else-if="item.destinationLocationID != null">mdi-map-marker</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-if="item.pointerName != null">{{ item.pointerName }}</v-list-item-title>
                                <v-list-item-title v-else-if="item.destinationLocationID != null">
                                    <BT-Entity
                                        :itemValue="item.destinationLocationID"
                                        navigation="public-locations"
                                        single
                                        textFilter="toFamiliarLocationLine"
                                        useLocalCache />
                                </v-list-item-title>
                                <v-list-item-title v-else-if="item.boundary != null">Area</v-list-item-title>
                                <v-list-item-subtitle v-if="item.fulfillerAgreementID == null">Self Fulfill</v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <BT-Entity
                                        :itemValue="item.fulfillerAgreementID"
                                        itemText="seller.companyName"
                                        navigation="couriers-fulfilling" />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <BT-Btn leftIcon="mdi-pencil" small @click="addNew(item)" />
                                    <BT-Btn leftIcon="mdi-delete" small @click="remove(item)" />
                                </v-row>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add another fulfillment pointer?</p>
                </v-tab-item>

                <!--self-fulfill only-->
                <v-tab-item>
                    <p>Please specify the area that you cover.  (It could be all of Australia or just a very specific suburb.)</p>
                    
                    <Setup-Info>
                        If you want to use BlitzIt Web to automatically select who to use to fulfill orders (ie. yourself), then you need to specify the geographical area that you are willing to cover.
                        Any orders with destinations outside of this area will simply require you to manually decide who is going to fulfill the order.
                    </Setup-Info>

                    <GmapMap
                        v-if="newItem != null && newItem.boundary != null"
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 50vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <gmap-polygon
                                @paths_changed="($event) => { updatePointerPaths($event, newItem) }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundary"
                                :strokeWeight="1" />
                    </GmapMap>

                    <v-slide-x-transition>
                        <BT-Btn
                            v-if="newItem != null && (newItem.id == null || $BlitzIt.tracker.isChanged(newItem))"
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Save"
                            @click="save(next)" />
                    </v-slide-x-transition>

                </v-tab-item>

                <!--self or 3PL-->
                <v-tab-item>
                    <BT-List-Item-Check
                        @change="selfFulfill"
                        class="my-3"
                        subtitle="A geographical area for orders that we will fill ourselves."
                        title="Self Fulfillment" />
                    
                    <BT-List-Item-Check
                        v-if="!select3PL"
                        @true="selectService"
                        class="my-3"
                        subtitle="A geographical area for orders that a third party will fill."
                        title="3PL Service" />
                    
                    <BT-Select-List-Box
                        v-if="select3PL"
                        @change="stage += 1"
                        v-model="newItem.fulfillerAgreementID"
                        height="50vh"
                        isEditing
                        isSelecting
                        itemText="seller.companyName"
                        label="3PL Services"
                        navigation="couriers-fulfilling" />
                </v-tab-item>

                <v-tab-item>
                    <BT-List-Item-Check
                        @true="selectBoundary"
                        class="my-3"
                        subtitle="For orders going to a geographical area."
                        title="Geographical Area" />
                    
                    <BT-List-Item-Check
                        @true="selectLocation"
                        class="my-3"
                        subtitle="For orders going to a specific customer's location."
                        title="Customer's Location" />

                </v-tab-item>

                <!--boundary-->
                <v-tab-item>
                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 50vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <!-- <gmap-marker
                                v-for="(position, index) in filteredJourneyLocationMarkers"
                                @click="selectDestination(position)"
                                clickable
                                :icon="{ anchor: { x: 13, y: 23 }, path: pinSVG(), fillOpacity: 1, fillColor: position.deliveredOn != null ? '#4CAF50' : '#FF5252' }"
                                :position="position"
                                :key="index + 'b'" /> -->

                            <gmap-polygon
                                v-for="(pointer, index) in otherBoundaryPointers"
                                :fillOpacity="0.5"
                                :key="index + 'a'"
                                :paths="pointer.boundary"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem) }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundary"
                                :strokeWeight="1" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />

                </v-tab-item>

                <!--weekday-->
                <v-tab-item v-if="newItem != null">
                    <p>And does this apply always or only certain days of the week?</p>

                    <BT-Select-List
                        :canSearch="false"
                        :items="weekdayOptions()"
                        itemText=""
                        v-model="newItem.weekdays"
                        returnCSV />

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                    
                </v-tab-item>

                <!--name-->
                <v-tab-item v-if="newItem != null">
                    <p>You can give it a name if you want.  ...might make it easier for later reference.</p>

                    <BT-Field-String
                        label="Pointer Name"
                        v-model="newItem.pointerName"
                        isEditing />
                    
                        <!-- <BT-Select-List-Box
                        height="50vh"
                        isEditing
                        isSelecting
                        itemText="seller.companyName"
                        label="3PL Services"
                        navigation="couriers-fulfilling" /> -->

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Save"
                        @click="save" />
                </v-tab-item>

            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Fulfiller-Pointer-Setup',
    components: {
        // BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        //BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        //SetupExample: () => import('~home/setup/Setup-Example.vue'),
        // SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        //SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            boundaryPointers: [],
            center: { lat: -38, lng: 144 },
            isSelfFulfiller: false,
            loadingMsg: null,
            locationPointers: [],
            newItem: null,
            otherBoundaryPointers: [],
            otherLocationPointers: [],
            pointers: [],
            refreshList: false,
            showSelectCustomer: false,
            showSelectLocation: false,
            select3PL: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.select3PL = false;

            this.newItem = j || {
                id: null,
                rowVersion: null,
                pointerName: null,
                boundary: this.getAreaAround(this.center, 1),
                destinationLocationID: null,
                fulfillerAgreementID: null,
                weekdays: 'Always'
            };

            if (j != null) {
                this.otherBoundaryPointers = this.boundaryPointers.filter(x => x.id != j.id);
                this.otherLocationPointers = this.locationPointers.filter(x => x.id != j.id);
                this.stage = 4;
            }
            else {
                this.otherBoundaryPointers = this.boundaryPointers;
                this.otherLocationPointers = this.locationPointers;
                this.stage = 2;
            }
        },
        async pullPointers(list) {
            if (this.settings.data.orderFulfillingForSelf &&
                !this.settings.data.orderFulfillingForOthers &&
                !this.settings.data.orderFulfillingByOthers) {
                if (!this.isLengthyArray(list)) {
                    //add pointer
                    this.newItem = this.$BlitzIt.tracker.track({
                        id: null,
                        rowVersion: null,
                        pointerName: null,
                        boundary: this.getAreaAround(this.center, 2),
                        destinationLocationID: null,
                        fulfillerAgreementID: null,
                        weekdays: 'Always'
                    }, {
                        trackProps: ['pointerName', 'boundary', 'destinationLocationID', 'fulfillerAgreementID', 'weekdays']
                    });

                    this.stage = 1;
                    this.isSelfFulfiller = true;
                }
                else if (this.isArrayOfLength(list, 1)) {
                    var p = list[0];
                    if (p.boundary != null && p.fulfillerAgreementID == null && p.destinationLocationID == null) {
                        this.newItem = this.$BlitzIt.tracker.track(p, {
                            trackProps: ['pointerName', 'boundary', 'destinationLocationID', 'fulfillerAgreementID', 'weekdays']
                        });
                    }

                    this.stage = 1;
                    this.isSelfFulfiller = true;
                }
                else {
                    this.stage = 0;
                }
            }
            else {
                this.stage = 0;
            }

            this.boundaryPointers = list.filter(x => x.boundary != null);
            this.locationPointers = list.filter(x => x.destinationLocationID != null);
            this.pointers = list;

            return list;
        },
        async remove(item) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('fulfiller-pointers', item.id);
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        selectBoundary() {
            this.newItem.destinationLocationID = null;

            if (this.newItem.boundary == null) {
                this.newItem.boundary = this.getAreaAround(this.center, 1);
            }

            this.stage += 1;
        },
        async selectLocation() {
            this.newItem.boundary = null;
            var location = null;

            var c = await this.$selectItem({ 
                navigation: 'customers', 
                itemText: 'buyer.companyName', 
                params: { 
                    properties: 'ID,Buyer' } 
                });

            if (c != null) {
                var policy = await this.$BlitzIt.store.get('suppliers', c.id, null, false, null, '/Locations/Locations');
                if (policy.possibleLocations.length > 1) {
                    location = await this.$selectItemFrom({ 
                        items: policy.possibleLocations, 
                        textFilter: 'toFamiliarLocationLine' 
                    });
                }
                else if (policy.possibleLocations.length == 1) {
                    location = policy.possibleLocations[0];
                }
            }

            this.newItem.destinationLocationID = location.id;
            this.stage += 2;
        },
        selfFulfill() {
            this.select3PL = false;
            this.newItem.fulfillerAgreementID = null;
            this.stage += 1;
        },
        selectService() {
            this.select3PL = true;
        },
        async save(nextFunc) {
            try {
                this.loadingMsg = 'Saving';

                if (this.newItem != null) {
                    var res = null;

                    if (this.newItem.id == null) {
                        res = await this.$BlitzIt.store.post('fulfiller-pointers', this.newItem);
                    }
                    else {
                        res = await this.$BlitzIt.store.patch('fulfiller-pointers', this.newItem);
                    }

                    this.newItem.id = res.id;
                    this.newItem.rowVersion = res.rowVersion;
                    this.$BlitzIt.tracker.reset(this.newItem);
                }
                
                if (!this.isSelfFulfiller) {
                    this.stage = 0;
                    this.refreshList = !this.refreshList;
                }
            }
            finally {
                this.loadingMsg = null;

                if (this.isSelfFulfiller && nextFunc != null) {
                    nextFunc();
                }
            }
            
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];

            zone.changed = true;
        },
    }
}
</script>