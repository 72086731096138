var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Setup-Step',{attrs:{"canNavigate":!_vm.isSelfFulfiller,"fluid":"","hideRestart":_vm.isSelfFulfiller,"hideYesNo":_vm.isSelfFulfiller,"loadingMsg":_vm.loadingMsg,"settings":_vm.settings,"showSkip":_vm.isSelfFulfiller,"stage":_vm.stage,"step":"fulfiller-pointers","title":"Fulfillment Coverage"},on:{"yes":function (f) { return _vm.addNew(); },"no":function (f) { return f(); },"update:stage":function($event){_vm.stage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var next = ref.next;
return [_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}},[_c('v-tab-item',[_c('p',[_vm._v("In order for BlitzIt Web to automatically select who is fulfilling an order, you need to specify what areas each 3PL service covers. You can do that by adding fulfillment pointers below.")]),_c('BT-List-Endless',{attrs:{"canSearch":false,"loadingMsg":_vm.loadingMsg,"maxHeight":"60vh","navigation":"fulfiller-pointers","onPullSuccessAsync":_vm.pullPointers,"refreshToggle":_vm.refreshList},on:{"update:loadingMsg":function($event){_vm.loadingMsg=$event},"update:loading-msg":function($event){_vm.loadingMsg=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[(item.boundary != null)?_c('v-icon',{staticClass:"my-auto"},[_vm._v("mdi-texture-box")]):(item.destinationLocationID != null)?_c('v-icon',[_vm._v("mdi-map-marker")]):_vm._e()],1),_c('v-list-item-content',[(item.pointerName != null)?_c('v-list-item-title',[_vm._v(_vm._s(item.pointerName))]):(item.destinationLocationID != null)?_c('v-list-item-title',[_c('BT-Entity',{attrs:{"itemValue":item.destinationLocationID,"navigation":"public-locations","single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})],1):(item.boundary != null)?_c('v-list-item-title',[_vm._v("Area")]):_vm._e(),(item.fulfillerAgreementID == null)?_c('v-list-item-subtitle',[_vm._v("Self Fulfill")]):_c('v-list-item-subtitle',[_c('BT-Entity',{attrs:{"itemValue":item.fulfillerAgreementID,"itemText":"seller.companyName","navigation":"couriers-fulfilling"}})],1)],1),_c('v-list-item-action',[_c('v-row',{attrs:{"no-gutters":""}},[_c('BT-Btn',{attrs:{"leftIcon":"mdi-pencil","small":""},on:{"click":function($event){return _vm.addNew(item)}}}),_c('BT-Btn',{attrs:{"leftIcon":"mdi-delete","small":""},on:{"click":function($event){return _vm.remove(item)}}})],1)],1)]}}],null,true)}),_c('p',{staticClass:"my-4"},[_vm._v("Would you like to add another fulfillment pointer?")])],1),_c('v-tab-item',[_c('p',[_vm._v("Please specify the area that you cover. (It could be all of Australia or just a very specific suburb.)")]),_c('Setup-Info',[_vm._v(" If you want to use BlitzIt Web to automatically select who to use to fulfill orders (ie. yourself), then you need to specify the geographical area that you are willing to cover. Any orders with destinations outside of this area will simply require you to manually decide who is going to fulfill the order. ")]),(_vm.newItem != null && _vm.newItem.boundary != null)?_c('GmapMap',{ref:"locMap",staticStyle:{"width":"100%","height":"50vh"},attrs:{"center":_vm.center,"zoom":7,"options":{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }}},[_c('gmap-polygon',{attrs:{"clickable":"","editable":"","fillOpacity":0.5,"paths":_vm.newItem.boundary,"strokeWeight":1},on:{"paths_changed":function ($event) { _vm.updatePointerPaths($event, _vm.newItem) }}})],1):_vm._e(),_c('v-slide-x-transition',[(_vm.newItem != null && (_vm.newItem.id == null || _vm.$BlitzIt.tracker.isChanged(_vm.newItem)))?_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Save"},on:{"click":function($event){return _vm.save(next)}}}):_vm._e()],1)],1),_c('v-tab-item',[_c('BT-List-Item-Check',{staticClass:"my-3",attrs:{"subtitle":"A geographical area for orders that we will fill ourselves.","title":"Self Fulfillment"},on:{"change":_vm.selfFulfill}}),(!_vm.select3PL)?_c('BT-List-Item-Check',{staticClass:"my-3",attrs:{"subtitle":"A geographical area for orders that a third party will fill.","title":"3PL Service"},on:{"true":_vm.selectService}}):_vm._e(),(_vm.select3PL)?_c('BT-Select-List-Box',{attrs:{"height":"50vh","isEditing":"","isSelecting":"","itemText":"seller.companyName","label":"3PL Services","navigation":"couriers-fulfilling"},on:{"change":function($event){_vm.stage += 1}},model:{value:(_vm.newItem.fulfillerAgreementID),callback:function ($$v) {_vm.$set(_vm.newItem, "fulfillerAgreementID", $$v)},expression:"newItem.fulfillerAgreementID"}}):_vm._e()],1),_c('v-tab-item',[_c('BT-List-Item-Check',{staticClass:"my-3",attrs:{"subtitle":"For orders going to a geographical area.","title":"Geographical Area"},on:{"true":_vm.selectBoundary}}),_c('BT-List-Item-Check',{staticClass:"my-3",attrs:{"subtitle":"For orders going to a specific customer's location.","title":"Customer's Location"},on:{"true":_vm.selectLocation}})],1),_c('v-tab-item',[_c('GmapMap',{ref:"locMap",staticStyle:{"width":"100%","height":"50vh"},attrs:{"center":_vm.center,"zoom":7,"options":{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }}},[_vm._l((_vm.otherBoundaryPointers),function(pointer,index){return _c('gmap-polygon',{key:index + 'a',attrs:{"fillOpacity":0.5,"paths":pointer.boundary,"strokeWeight":1}})}),(_vm.newItem != null)?_c('gmap-polygon',{attrs:{"clickable":"","editable":"","fillOpacity":0.5,"paths":_vm.newItem.boundary,"strokeWeight":1},on:{"paths_changed":function ($event) { _vm.updatePointerPaths($event, _vm.newItem) }}}):_vm._e()],2),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Next"},on:{"click":function($event){_vm.stage += 1}}})],1),(_vm.newItem != null)?_c('v-tab-item',[_c('p',[_vm._v("And does this apply always or only certain days of the week?")]),_c('BT-Select-List',{attrs:{"canSearch":false,"items":_vm.weekdayOptions(),"itemText":"","returnCSV":""},model:{value:(_vm.newItem.weekdays),callback:function ($$v) {_vm.$set(_vm.newItem, "weekdays", $$v)},expression:"newItem.weekdays"}}),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Next"},on:{"click":function($event){_vm.stage += 1}}})],1):_vm._e(),(_vm.newItem != null)?_c('v-tab-item',[_c('p',[_vm._v("You can give it a name if you want. ...might make it easier for later reference.")]),_c('BT-Field-String',{attrs:{"label":"Pointer Name","isEditing":""},model:{value:(_vm.newItem.pointerName),callback:function ($$v) {_vm.$set(_vm.newItem, "pointerName", $$v)},expression:"newItem.pointerName"}}),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Save"},on:{"click":_vm.save}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }